import type { Colors } from "@/lib/colors-category"

import { Fragment } from "react"
import { GoHomeFill } from "react-icons/go"

import { BreadcrumbBase } from "@/components/breadcrumb-base"
import { RichText } from "@/components/rich-text"
import { ColorsCategory } from "@/lib/colors-category"
import { cn } from "@/lib/utils"


interface HeaderProps {
  title: string
  description?: string
  content?: any
  color?: Colors
  slug?: string
  items: Array<{ title: string, slug?: string | null }>
}

export function Header({ title, description, content, color, items }: HeaderProps) {
  return (
    <header>
      <BreadcrumbBase>
        <BreadcrumbBase.List>
          <BreadcrumbBase.Item>
            <BreadcrumbBase.Link href="/">
              <GoHomeFill aria-label="Destaques" title="Destaques" />
            </BreadcrumbBase.Link>
          </BreadcrumbBase.Item>
          {items.map((item) => (
            <Fragment key={item.title}>
              <BreadcrumbBase.Separator />
              <BreadcrumbBase.Item>
                {item.title}
              </BreadcrumbBase.Item>
            </Fragment>
          ))}
        </BreadcrumbBase.List>
      </BreadcrumbBase>
      <h1 className={cn("text-2xl text-primary font-bold mt-6", color && ColorsCategory[color])}>{title}</h1>
      {description && <p className="text-primary/70 text-sm border-t mt-2 pt-2">{description}</p>}
      {content && (
        <div className="prose-p:text-primary/70 text-sm border-t mt-2 pt-2">
          <RichText content={content} />
        </div>
      )}
    </header>
  )
}
