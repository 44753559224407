import { useFetcher } from "@remix-run/react"
import { useEffect, useRef, useState } from "react"
import { CgSpinner } from "react-icons/cg"

import { PostCover } from "@/components/post-cover"

interface InfiniteScrollProps {
  initialPosts: any[]
  initialNextPage?: number
}

export const InfiniteScroll = ({ initialPosts, initialNextPage }: InfiniteScrollProps) => {
  const fetcher = useFetcher()
  const [posts, setPosts] = useState(initialPosts)
  const observer = useRef<IntersectionObserver | null>(null)
  const lastPostRef = useRef<HTMLDivElement | null>(null)
  const nextPage = fetcher.data ? fetcher.data.nextPage : initialNextPage

  useEffect(() => {
    if (!fetcher.data || fetcher.state === "loading") {
      return
    }

    if (fetcher.data) {
      /* @ts-ignore */
      const newPosts = fetcher.data.posts
      setPosts((prevPosts) => [...prevPosts, ...newPosts])
    }
  }, [fetcher])

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && nextPage && fetcher.state === "idle") {
        fetcher.load(`?page=${nextPage}`)
      }
    })

    if (lastPostRef.current) {
      observer.current.observe(lastPostRef.current)
    }

    return () => {
      if (observer.current) observer.current.disconnect()
    }
  }, [fetcher, nextPage])

  return (
    <>
      {posts.map(post => (
        <PostCover key={post.id} {...post} />
      ))}
      <div ref={lastPostRef} className="col-span-full">
        {nextPage && (
          <p className="flex justify-center gap-2 items-center">
            <CgSpinner className="animate-spin" />
            <span className="text-sm text-primary/80">Carregando</span>
          </p>
        )}
      </div >
    </>
  )
}