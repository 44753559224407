
import type { FormattedPostProps } from "@/controllers/post-controller.server"

import { Link } from "@remix-run/react"
import { parseISO, formatDistanceToNowStrict } from "date-fns"
import { ptBR } from "date-fns/locale"

import { Blurhash } from "@/components/blurhash"
import { BgColorsCategory, ColorsCategory } from "@/lib/colors-category"
import { cn } from "@/lib/utils"

const Default = ({ title, media, excerpt, pathname, category, publishedAt }: FormattedPostProps) => (
  <article className="flex flex-col gap-2">
    {media && (
      <Link to={pathname}>
        <figure className="flex flex-col gap-2 lg:gap-3 mb-3">
          <Blurhash hash={media.blurhash} className="rounded-lg">
            <img className="rounded-lg" src={media.url} alt={media.alt} width={media.width} height={media.height} />
          </Blurhash>
        </figure>
      </Link>
    )}
    <h2 className={cn("text-xl font-medium hover:underline underline-offset-4", ColorsCategory[category.color])}><Link to={pathname}>{title}</Link></h2>
    <p>{excerpt}</p>
    <p className="flex gap-1 text-sm text-primary/80">
      <time itemProp="dateModified" dateTime={publishedAt}>{formatDistanceToNowStrict(parseISO(publishedAt), { locale: ptBR, addSuffix: true })}</time>
    </p>
  </article>
)

const LatestNews = ({ title, media, excerpt, pathname, category, publishedAt }: FormattedPostProps) => (
  <article className="flex flex-col gap-2 border-t pt-8 lg:pt-8 first-of-type:border-0 first-of-type:pt-0">
    <div>
      <p className={cn("text-xs tracking-wider font-medium uppercase flex gap-1.5 items-center hover:opacity-75", ColorsCategory[category.color])}>
        <span className={cn("size-1.5 rounded-sm inline-block", BgColorsCategory[category.color])}></span>
        <Link to={`/${category.slug}`}>{category.title}</Link>
      </p>
    </div>
    <div className="grid grid-cols-12 items-start gap-4 md:gap-6">
      <div className="col-span-9 flex flex-col gap-1.5">
        <h2 className={cn("text-lg leading-snug lg:text-xl font-bold hover:text-primary/80")}><Link to={pathname}>{title}</Link></h2>
        <p className="text-primary/80 text-sm">{excerpt}</p>
      </div>
      {media && (
        <Link to={pathname} className="col-span-3">
          <Blurhash hash={media.blurhash} className="rounded-lg">
            <img className="rounded-lg aspect-[1] object-cover object-center" src={media.url} alt={media.alt} width={media.width} height={media.height} />
          </Blurhash>
        </Link>
      )}
    </div>
    <p className="flex gap-1 text-sm text-primary/80">
      <time itemProp="dateModified" dateTime={publishedAt}>{formatDistanceToNowStrict(parseISO(publishedAt), { locale: ptBR, addSuffix: true })}</time>
    </p>
  </article>
)

const Trailer = ({ title, media, pathname }: FormattedPostProps) => (
  <article className="flex flex-col sm:hover:scale-110 transition-transform">
    {media && (
      <Link to={pathname}>
        <figure className="flex flex-col gap-2 lg:gap-3 mb-3">
          <Blurhash hash={media.blurhash} className="rounded-lg">
            <img className="rounded-lg object-cover object-center" src={media.url} alt={media.alt} width={media.width} height={media.height} />
          </Blurhash>
        </figure>
      </Link>
    )}
    <h2 className={cn("text-sm font-medium hover:underline underline-offset-4")}><Link to={pathname}>{title}</Link></h2>
  </article>
)

export const PostCover = (props: FormattedPostProps) => {
  if (props.layout === "default") return <Default {...props} />
  if (props.layout === "ultimas-noticias") return <LatestNews {...props} />
  if (props.layout === "trailer") return <Trailer {...props} />
}